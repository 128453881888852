import React, { useEffect, useRef, useState } from 'react';
import { MCHammer } from '../../../components/Loader';
import { useGlobalService } from '../../../services/GlobalService';
import { notify } from '../../../components/Toast/Toast';

const SayDiscoveryDashboard: React.FC = () => {
  const { loading, error, sayDiscoveryGuard } = useGlobalService();

  const [token, setToken] = useState('');

  useEffect(() => {
    sayDiscoveryGuard().then((token) => {
      setToken(token);
    });
  }, []);

  useEffect(() => {
    error && notify('error', error);
  }, [error]);

  if (!token.length || loading) {
    return <MCHammer />;
  }

  return (
    <>
      <iframe
        src={`https://discovery.saygames.io/public-api/saylauncher/dashboard?token=${token}`}
        className="w-100"
        style={Object.assign({
          minHeight: 'calc(100vh - 70px)',
        })}
      />
    </>
  );
};

export default SayDiscoveryDashboard;
