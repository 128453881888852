import React, { SyntheticEvent, useContext, useEffect, useMemo } from 'react';
import {
  Button,
  ButtonGroup,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ToggleButton,
} from 'react-bootstrap';
import { Formik, FormikValues } from 'formik';
import { reducerActionType } from './models';
import { useProjectService } from '../../services/ProjectsService';
import { notify } from '../../components/Toast/Toast';
import * as Yup from 'yup';
import DropdownItem from 'react-bootstrap/DropdownItem';
import styled from 'styled-components';
import { Asterisk } from 'react-bootstrap-icons';
import { ProjectsStateContext } from './ProjectList';
import { makeProjectsFilterField } from './_utils';
import { DropdownComponent } from '../../components/Dropdown';

export const AddNewProjectModal: React.FC = () => {
  const { state, dispatch } = useContext(ProjectsStateContext);

  const { loading, error, addNewProject } = useProjectService();

  useEffect(() => {
    error && notify('error', error);
  }, [error]);

  const onSubmit = (values: FormikValues) => {
    let tempPlatform = '';
    let isAmazon = false;
    switch (values.Platform) {
      case 'iOS':
        tempPlatform = 'i';
        break;
      case 'Android':
        tempPlatform = 'a';
        break;
      case 'Web':
        tempPlatform = 'w';
        break;
      case 'Amazon':
        tempPlatform = 'a';
        isAmazon = true;
        break;
    }

    const request = {
      name: values.Name,
      alias: values.Alias,
      platform: tempPlatform,
      isAmazon: isAmazon,
      bundle: values.Bundle,
      company: values.Company,
    };
    addNewProject(request).then((resp) => {
      dispatch({
        type: reducerActionType.SET_PROJECTS,
        payload: makeProjectsFilterField(resp.projects),
      });
      dispatch({
        type: reducerActionType.SET_SHOW_ADD_NEW_PROJECT_MODAL,
        payload: false,
      });
      notify('success', `${resp.Name} has been added`);
    });
  };

  const handleClose = () => {
    dispatch({
      type: reducerActionType.SET_SHOW_ADD_NEW_PROJECT_MODAL,
      payload: false,
    });
  };

  const schema = useMemo(() => {
    return Yup.object().shape({
      Name: Yup.string().required(),
      Alias: Yup.string()
        .required()
        .min(6)
        .max(6)
        .test('regex', 'Use only lowercase letters and numbers', (value) => {
          if (value) {
            return /^[a-z\d]+$/.test(value);
          }
          return false;
        }),
      Platform: Yup.string()
        .required()
        .oneOf(
          ['iOS', 'Android', 'Web', 'Amazon'],
          'Must be iOS, Android or Web'
        ),
      Bundle: Yup.string()
        .required()
        .test('regex', 'Use only lowercase letters and numbers', (value) => {
          if (value) {
            return /^[a-z\d.]+.[a-z\d.]+.[a-z\d]+$/.test(value);
          }
          return false;
        }),
      Company: Yup.string()
        .required()
        .oneOf(state.companies, 'Must be one of companies'),
    });
  }, [state.companies]);

  const initialState = useMemo(() => {
    return {
      Name: '',
      Alias: '',
      Platform: 'Android',
      Bundle: '',
      Company: state.companies[0],
    };
  }, [state.companies]);

  return (
    <Modal
      show={state.showAddNewProjectModal}
      backdrop={'static'}
      onHide={handleClose}
      data-testid="mdlProjectListAddNewGame"
    >
      <ModalHeader closeButton>
        <ModalTitle>Add New Game</ModalTitle>
      </ModalHeader>
      <Formik
        validationSchema={schema}
        initialValues={initialState}
        onSubmit={onSubmit}
        validateOnChange={false}
      >
        {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
          <Form noValidate onSubmit={handleSubmit} autoComplete="off">
            <ModalBody>
              <FormGroup className="position-relative mb-3">
                <FormLabel>
                  Project Name
                  <Asterisk
                    width={6}
                    height={6}
                    color="red"
                    className="align-text-top"
                  />
                </FormLabel>
                <FormControl
                  data-testid="txtProjectListModalAddNewGameProjectName"
                  name="Name"
                  value={values.Name}
                  onChange={handleChange}
                  isInvalid={!!errors.Name}
                />
                <FormControl.Feedback type="invalid" tooltip>
                  {errors.Name}
                </FormControl.Feedback>
              </FormGroup>
              <FormGroup className="position-relative mb-3">
                <FormLabel>
                  Alias
                  <Asterisk
                    width={6}
                    height={6}
                    color="red"
                    className="align-text-top"
                  />
                </FormLabel>
                <FormControl
                  data-testid="txtProjectListModalAddNewGameAlias"
                  name="Alias"
                  value={values.Alias}
                  onChange={handleChange}
                  isInvalid={!!errors.Alias}
                  placeholder="Must be 6 characters"
                />
                <FormControl.Feedback type="invalid" tooltip>
                  {errors.Alias}
                </FormControl.Feedback>
              </FormGroup>
              <FormGroup className="position-relative mb-3">
                <FormLabel>Platform</FormLabel>
                <ButtonGroup className="d-flex flex-grow-1">
                  {['Android', 'iOS', 'Web', 'Amazon'].map((os) => (
                    <ToggleButton
                      data-testid={`rdoProjectListModalAddNewGamePlatform${os}`}
                      key={os}
                      id={`radio-${os}`}
                      type="radio"
                      name="Platform"
                      variant={
                        values.Platform === os ? 'primary' : 'outline-primary'
                      }
                      checked={values.Platform === os}
                      value={os}
                      onChange={handleChange}
                    >
                      {os}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
                <p className="text-muted mt-1">Auto setup CY, Adjust, Max.</p>
                <FormControl.Feedback type="invalid" tooltip>
                  {errors.Platform}
                </FormControl.Feedback>
              </FormGroup>
              <FormGroup className="position-relative mb-3">
                <FormLabel>
                  Bundle
                  <Asterisk
                    width={6}
                    height={6}
                    color="red"
                    className="align-text-top"
                  />
                </FormLabel>
                <FormControl
                  data-testid="txtProjectListModalAddNewGameBundle"
                  name="Bundle"
                  placeholder={
                    values.Platform === 'Web'
                      ? 'web.some.game.web_store_name'
                      : ''
                  }
                  value={values.Bundle}
                  onChange={handleChange}
                  isInvalid={!!errors.Bundle}
                />
                <FormControl.Feedback type="invalid" tooltip>
                  {errors.Bundle}
                </FormControl.Feedback>
              </FormGroup>
              <FormGroup className="position-relative">
                <FormLabel>Company</FormLabel>
                <DropdownComponent
                  datatestid="ddlProjectListModalAddNewGameCompany"
                  autoClose={true}
                  title={values.Company}
                  onSelect={(e: SyntheticEvent<HTMLDivElement>) =>
                    setFieldValue('Company', e.toString())
                  }
                >
                  {state.companies.map((v) => (
                    <div key={v}>
                      <DropdownItem eventKey={v} active={values.Company === v}>
                        {v}
                      </DropdownItem>
                    </div>
                  ))}
                </DropdownComponent>
                <FormControl.Feedback type="invalid" tooltip>
                  {errors.Company}
                </FormControl.Feedback>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <>
                <Button
                  data-testid="btnProjectListModalAddNewGameClose"
                  variant="secondary"
                  disabled={loading}
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button
                  data-testid="btnProjectListModalAddNewGameAdd"
                  className="float-end"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? 'Loading...' : 'Add'}
                </Button>
              </>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
