import moment, { Moment } from 'moment';

export const PERMISSION_EDIT_USERS = 'edit-users';
export const PERMISSION_GAMES_INFO = 'games-info';
export const PERMISSION_TOTALS = 'totals';
export const PERMISSION_DISCOVERY = 'discovery';
export const PERMISSION_CREATIVES = 'creatives';
export const PERMISSION_HELP = 'help';
export const PERMISSION_FEEDBACK = 'feedback';
export const PERMISSION_MULTI_COMPANIES = 'multi-companies';
export const PERMISSION_DELETE_VERSION = 'delete-version';
export const PERMISSION_CLONE_VERSION = 'clone-version';
export const PERMISSION_EDIT_VERSIONS = 'edit-versions';
export const PERMISSION_CHANGE_SERVING = 'change-serving';
export const PERMISSION_VIEW_VERSION_DETAILS = 'view-version-details';
export const PERMISSION_UNLOCK_VERSION = 'unlock-version';
export const PERMISSION_RUN_BUILDS = 'run-builds';
export const PERMISSION_SAYKIT_SETTINGS = 'saykit-settings';
export const PERMISSION_MONETIZATION = 'monetization';
export const PERMISSION_CPI = 'cpi';
export const PERMISSION_SUPPORT = 'support';
export const PERMISSION_NOTIFICATIONS = 'notifications';
export const PERMISSION_NEW_GAME_REQUEST = 'new-game-request';
export const PERMISSION_DEVICES = 'devices';
export const PERMISSION_ADD_TIMEOUTS = 'add-timeouts';
export const PERMISSION_EDIT_TIMEOUTS = 'edit-timeouts';
export const PERMISSION_READ_EXPERIMENTS = 'read-experiments';
export const PERMISSION_ACTION_EXPERIMENTS = 'action-experiments';
export const PERMISSION_UNITY_WHITELIST = 'unity-whitelist';
export const PERMISSION_UNITY_BLACKLIST = 'unity-blacklist';
export const PERMISSION_SAYKIT_BLACKLIST = 'saykit-blacklist';
export const PERMISSION_ANR = 'anr';
export const PERMISSION_RUNTIME_CONFIG = 'runtime-config';
export const PERMISSION_PROJECTS_RELEASE_HISTORY = 'projects-release-history';
export const PERMISSION_GAMESUPPORT_TEMPLATE = 'gamesupport-template';
export const PERMISSION_GLOBAL_EXPERIMENTS = 'global-experiments';
export const PERMISSION_PROJECT_SETTINGS = 'project-settings';

export const PERMISSION_TSAREVICH = 'tsarevich';

export const ROLE_ADMIN = 'Admin';
export const ROLE_COMPANY = 'Company';
export const ROLE_USER = 'User';
export const ROLE_QA_MANAGER = 'QAManager';
export const ROLE_QA = 'QA';
export const ROLE_VIEWER = 'Viewer';
export const ROLE_GUEST = 'Guest';

export const MainColor = '#00c565';
export const WarningColor = '#ffc107';
export const SecondaryColor = '#6c757d';
export const DangerColor = '#dc3545';

export const prettifyName = (name: string) => {
  const iOS = '(iOS)';
  const Android = '(Android)';
  const Web = '(Web)';
  const China = 'China';

  if (name.indexOf(iOS) !== -1) {
    name = name.slice(0, name.indexOf(iOS));
  }
  if (name.indexOf(Android) !== -1) {
    name = name.slice(0, name.indexOf(Android));
  }
  if (name.indexOf(Web) !== -1) {
    name = name.slice(0, name.indexOf(Web));
  }
  if (name.indexOf(China) !== -1) {
    name = name.slice(0, name.indexOf(China));
  }

  return name.trim();
};

export const versionSegmentView = (version: string, segment: number) => {
  return segment === 0 ? version : `${version}.${segment}`;
};

export const capitalizeFirstLetter = (name: string): string => {
  return name.charAt(0).toUpperCase() + name.slice(1);
};

export const containsNumbers = (v: string) => {
  return /[0-9]/.test(v);
};

export const isDesktop = () => {
  return window.innerWidth >= 768;
};

export type LocationState = {
  from: string;
};

export const versionToInt = (version: string) => {
  let s = version.split('.');
  if (s.length >= 3) {
    let p0 = parseInt(s[0]);
    let p1 = parseInt(s[1]);
    let p2 = parseInt(s[2]);
    if (s.length > 3) {
      let p3 = parseInt(s[3]);
      return p0 * 1000 * 1000 * 1000 + p1 * 1000 * 1000 + p2 * 1000 + p3;
    }

    return p0 * 1000 * 1000 * 1000 + p1 * 1000 * 1000 + p2 * 1000;
  }
  return 0;
};

export const calculateHash = (value: string) => {
  let hash = 0,
    i,
    chr;

  if (value.length === 0) return hash;

  for (i = 0; i < value.length; i++) {
    chr = value.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return hash;
};

export const generateColorByAppKey = (appKey: string) => {
  // Вычисление хеша идентификатора
  let hash = 0;
  for (let i = 0; i < appKey.length; i++) {
    hash = appKey.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Генерация цвета в формате HEX
  return '#' + (hash & 0x00ffffff).toString(16).toUpperCase().slice(-6);
};

export const sortUnityVersions = (unityVersions: string[]) => {
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base',
  });

  return unityVersions.sort(collator.compare).reverse();
};

export type DateRangeValue = [Moment | null, Moment | null];

export const PlatformList = () => {
  return ['All', 'iOS', 'Android', 'Web'];
};

export const StoreList = () => {
  return [
    'All',
    'Apple',
    'China',
    'Google',
    'Amazon',
    'Xiaomi',
    'Huawei',
    'Steam',
  ];
};

export const PlatformStoreList = () => {
  let platformStore = {} as { [key: string]: string[] };
  platformStore['All'] = [];
  platformStore['Web'] = [];
  platformStore['iOS'] = ['All', 'Apple', 'China'];
  platformStore['Android'] = [
    'All',
    'Google',
    'Amazon',
    'Xiaomi',
    'Huawei',
    'Steam',
  ];

  return platformStore;
};

export const projectSortPriority: Map<string, number> = new Map([
  ['published', 5],
  ['softlaunched', 4],
  ['testing', 3],
  ['hold', 2],
  ['', 1],
]);

export const localeSortPriority = new Map<string, number>([
  ['en', 18],
  ['ru', 17],
  ['de', 16],
  ['fr', 15],
  ['es', 14],
  ['it', 13],
  ['pt', 12],
  ['ja', 11],
  ['zh', 10],
  ['ko', 9],
  ['hi', 8],
  ['ar', 7],
  ['uk', 6],
  ['pl', 5],
  ['tr', 4],
  ['id', 3],
  ['th', 2],
  ['vi', 1],
  ['', -1],
]);

export const formatNumber = (num: number): string => {
  if (num < 1000) {
    return num.toString();
  } else if (num < 1000000) {
    return (num / 1000).toFixed(1) + 'k';
  } else if (num < 1000000000) {
    return (num / 1000000).toFixed(3) + 'm';
  } else {
    return (num / 1000000000).toFixed(2) + 'b';
  }
};

export const getDatesBetween = (startDate: string, endDate: string) => {
  const dates: string[] = [];
  let currentDate = moment(startDate);
  const stopDate = moment(endDate);

  while (currentDate <= stopDate) {
    dates.push(currentDate.format('YYYY-MM-DD'));
    currentDate = currentDate.clone().add(1, 'day');
  }

  return dates;
};

export const isValidJSON = (v: string) => {
  try {
    JSON.parse(v);
  } catch (e) {
    return false;
  }
  return true;
};

export const prettifyPlatform = (value: string) => {
  switch (value) {
    case 'android':
      return 'Android';
    case 'ios':
      return 'iOS';
    case 'web':
      return 'Web';
    case 'steam':
      return 'Steam';
    case 'pc':
      return 'PC';
    default:
      return 'unknown';
  }
};
