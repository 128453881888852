import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { ArrowDownShort, ArrowUpShort } from 'react-bootstrap-icons';
import styled from 'styled-components';

const StyledTopBtn = styled.button`
  z-index: 1;
  cursor: pointer;
  opacity: 0.7;
  padding: 0;
  margin: 0;
  position: fixed;
  bottom: 6rem;
  right: 3rem;
  width: 48px;
  height: 48px;
  background-color: #adb5bd;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  border-radius: 57px;
  border: none;

  &:hover {
    background-color: #adb5bd;
    opacity: 1;
  }
`;
const StyledBottomBtn = styled.button`
  z-index: 1;
  cursor: pointer;
  opacity: 0.7;
  padding: 0;
  margin: 0;
  position: fixed;
  bottom: 2rem;
  right: 3rem;
  width: 48px;
  height: 48px;
  background-color: #adb5bd;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  border-radius: 57px;
  border: none;

  &:hover {
    background-color: #adb5bd;
    opacity: 1;
  }
`;

export const ScrollToTopToBottomBtns: React.FC<{ Section: string }> = ({
  Section,
}) => {
  const [visibleTopBtn, setVisibleTopBtn] = useState(false);
  const [visibleBottomBtn, setVisibleBottomBtn] = useState(true);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > window.innerHeight) {
      setVisibleTopBtn(true);
    } else {
      setVisibleTopBtn(false);
    }
    if (
      scrolled <
      document.body.scrollHeight -
        document.body.offsetHeight -
        window.innerHeight
    ) {
      setVisibleBottomBtn(true);
    } else {
      setVisibleBottomBtn(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    document.addEventListener('scroll', toggleVisible);
    return () => document.removeEventListener('scroll', toggleVisible);
  }, []);

  return (
    <>
      <StyledTopBtn
        as={Button}
        data-testid="btnScrollToTopToBottomBtnsTop"
        onClick={scrollToTop}
        style={{ display: visibleTopBtn ? 'inline' : 'none' }}
      >
        <ArrowUpShort size={32} />
      </StyledTopBtn>
      <StyledBottomBtn
        as={Button}
        data-testid="btnScrollToTopToBottomBtnsBottom"
        onClick={scrollToBottom}
        style={{ display: visibleBottomBtn ? 'inline' : 'none' }}
      >
        <ArrowDownShort size={32} />
      </StyledBottomBtn>
    </>
  );
};
